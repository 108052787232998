import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { filter } from "./FilterHOC";
import { LogoHorizontal } from "../../assets/icons/Index";
import MobileNavbar from "./Mobile/MobileNavbar";
import "./Navbar.scss";

const MenuButton = styled(filter("color")(NavLink))`
  &.inactive {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: none;
    transition: all 0.8s ease-out;

    &:hover {
      div {
        width: 100%;
      }
    }

    p {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-size: clamp(1em, 1.5em, 1.25vw);
      font-weight: 500;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      background: none;
      color: ${(props) => (props.color === "home" ? "#ebf2fa" : "#483b32")};
    }

    div {
      display: flex;
      position: relative;
      width: 8px;
      height: 2px;
      align-self: flex-start;
      background-color: ${(props) =>
        props.color === "home" ? "#ebf2fa" : "#483b32"};
      transition: all 0.8s ease-out;
    }
  }

  &.active {
    p {
      font-weight: 700;
    }

    div {
      width: 100%;
    }
  }
`;

const Navbar = () => {
  const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      let currentValue;

      try {
        currentValue = JSON.parse(
          localStorage.getItem(key) || String(defaultValue)
        );
      } catch (error) {
        currentValue = defaultValue;
      }

      return currentValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
  };

  const [athome, setNotHome] = useLocalStorage("nothome", "home");

  useEffect(() => {
    if (window.location.href === "https://eljardindelosvolcanes.com/") {
      setNotHome("home");
    } else if (window.location.href === "https://eljardindelosvolcanes.com/#") {
      setNotHome("home");
    } else if (
      window.location.href === "https://www.eljardindelosvolcanes.com/"
    ) {
      setNotHome("home");
    } else if (
      window.location.href === "https://www.eljardindelosvolcanes.com/#"
    ) {
      setNotHome("home");
    } else if (
      window.location.href === "https://www.eljardindelosvolcanes.com/?"
    ) {
      setNotHome("home");
    } else if (window.location.href === "https://eljardindelosvolcanes.com/?") {
      setNotHome("home");
    } else {
      setNotHome(MobileNavbar.athome);
    }
  }, [window.location.href]);

  // useEffect(() => {
  //   setNotHome(MobileNavbar.athome);
  // }, [MobileNavbar.athome]);

  // const setLocation = () => {
  //   setNotHome(!athome);
  // };

  return (
    <div className="navbar_desktop">
      <motion.div
        className="navbar_desktop-logo"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 1.6,
          ease: "easeOut",
        }}
      >
        <LogoHorizontal
          style={{
            color: athome === "home" ? "#ebf2fa" : "#483b32",
          }}
        />
      </motion.div>
      <motion.div
        className="navbar_desktop-menu"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 1.4,
          ease: "easeOut",
        }}
      >
        <MenuButton
          to="/"
          end
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome("home")}
          color={athome}
        >
          <p>inicio</p>
          <div></div>
        </MenuButton>
        {/* <MenuButton
          to="/disponibilidad"
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome('nothome')}
          color={athome}
        >
          <p>disponibilidad</p>
          <div></div>
        </MenuButton> */}
        <MenuButton
          to="/como-llegar"
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome("nothome")}
          color={athome}
        >
          <p>¿cómo llegar?</p>
          <div></div>
        </MenuButton>
        {/* <MenuButton
          to="/blog"
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome("nothome")}
          color={athome}
        >
          <p>blog</p>
          <div></div>
        </MenuButton> */}
        {/* <MenuButton
          to="/cuenta"
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome("nothome")}
          color={athome}
        >
          <p>Cuenta</p>
          <div></div>
        </MenuButton>
        <MenuButton
          to="/chat"
          className="inactive"
          activeclassname="active"
          onClick={() => setNotHome("nothome")}
          color={athome}
        >
          <p>Chat</p>
          <div></div>
        </MenuButton> */}
      </motion.div>
      <MobileNavbar athome={athome} setNotHome={setNotHome} />
    </div>
  );
};

export default Navbar;
