import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { motion, useCycle } from "framer-motion";

import "./MobileNavbar.scss";
import { filter } from "../FilterHOC";
import { useRef } from "react";
import { useDimensions } from "./useDimensions";
import { useEffect } from "react";

const Path = (props) => (
  <motion.path fill="transparent" strokeWidth="3" stroke="#483B32" {...props} />
);

const sidebar = {
  open: (height = 1080) => ({
    clipPath: `circle(${height * 2 + 200}px at 95.5% 46px)`,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),

  closed: {
    clipPath: "circle(20px at 95.65% 46px)",
    opacity: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const MenuButton = styled(filter("color")(NavLink))`
  &.inactive {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: none;
    transition: all 0.8s ease-out;

    &:hover {
      div {
        width: 100%;
      }
    }

    p {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-size: clamp(1em, 2.5em, 3.25vw);
      font-weight: 500;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      background: none;
      color: #ebf2fa;
    }

    div {
      display: flex;
      position: relative;
      width: 8px;
      height: 2px;
      align-self: flex-start;
      background-color: #ebf2fa;
      transition: all 0.8s ease-out;
    }
  }

  &.active {
    p {
      font-weight: 700;
    }

    div {
      width: 100%;
    }
  }
`;

const MobileNavbar = ({ athome, setNotHome }) => {
  const [toggle, setToggle] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect(() => {
    document.body.style.overflow = toggle ? "hidden" : "auto";
  }, [toggle]);

  const handleHomeClick = () => {
    setToggle();
    setNotHome("home");
  };

  const handleNotHomeClick = () => {
    setToggle();
    setNotHome("nothome");
    athome = setNotHome;
  };

  return (
    <motion.div
      className="mobilenavbar"
      animate={toggle ? "open" : "closed"}
      initial={false}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background" variants={sidebar} />
      <div
        className="navbuttons__wrapper"
        style={{ display: toggle ? "flex" : "none" }}
      >
        <MenuButton
          to="/"
          end
          className="inactive"
          activeclassname="active"
          onClick={handleHomeClick}
        >
          <p>inicio</p>
          <div></div>
        </MenuButton>
        {/* <MenuButton
            to="/disponibilidad"
            className="inactive"
            activeclassname="active"
            onClick={handleNotHomeClick}
          >
            <p>disponibilidad</p>
            <div></div>
          </MenuButton> */}
        <MenuButton
          to="/como-llegar"
          className="inactive"
          activeclassname="active"
          onClick={handleNotHomeClick}
        >
          <p>¿cómo llegar?</p>
          <div></div>
        </MenuButton>
        {/* <MenuButton
            to="/blog"
            className="inactive"
            activeclassname="active"
            onClick={handleNotHomeClick}
          >
            <p>blog</p>
            <div></div>
          </MenuButton>
          <MenuButton
            to="/cuenta"
            className="inactive"
            activeclassname="active"
            onClick={handleNotHomeClick}
          >
            <p>cuenta</p>
            <div></div>
          </MenuButton>
          <MenuButton
            to="/chat"
            className="inactive"
            activeclassname="active"
            onClick={handleNotHomeClick}
          >
            <p>chat</p>
            <div></div>
          </MenuButton> */}
      </div>
      <div className="navbarbutton">
        <motion.div
          style={{ background: "none", height: "19px" }}
          onClick={() => setToggle()}
          animate={toggle ? "open" : "closed"}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 23 23"
            style={{ background: "none" }}
          >
            <Path
              variants={{
                closed: { d: "M 2 2.5 L 20 2.5" },
                open: { d: "M 3 16.5 L 17 2.5" },
              }}
              style={{ background: "none" }}
            />
            <Path
              d="M 8 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
              style={{ background: "none", right: 0 }}
            />
            <Path
              variants={{
                closed: { d: "M 2 16.346 L 20 16.346" },
                open: { d: "M 3 2.5 L 17 16.346" },
              }}
              style={{ background: "none" }}
            />
          </svg>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default MobileNavbar;
